import React from "react";

const Help = () => {
  return (
    <>
      <h3>これは何</h3>
      <div>
        ちょっとだけいたずらがしにくくなっている投書箱サービスです。
        <br />
        既存の投書箱サービスがなんとなく気に食わなかったので自分で作りました。
        <br />
        完全にβ版ですので、自己責任で利用してください。
      </div>
      <h3>特徴</h3>
      <div>
        送られてきた質問に、ID や UserAgent
        を付与するので、同一人物からのいたずら投稿に気づきやすいのが特徴です。
        <br />
        特定の ID や UserAgent をブロックする機能も実装予定です。
      </div>
      <div>
        さらに安全にするために、質問送信時にメールアドレスによる認証を要求することもできます。
        <br />
        このメールアドレスを受け取り主が見ることはできませんが、メールアドレスから計算した
        ID
        が付与されるため、同一のメールアドレスからの送信には気づくことができます。
        <br />
        捨てアド作成サービスなど、特定のドメインのメールアドレスは受け付けないようにする機能も実装予定です（めんどくさいのであんまりやる気ないけど）。
      </div>
      <h3>個人情報は漏れない？</h3>
      <div>
        <a href="https://wa3.i-3-i.info/word11949.html" target="_blank">
          このサイト
        </a>
        などが参考になりますが、このサービスのデータベースに IPアドレス
        やメールアドレスが平文のまま保存されることはなく、ハッシュ値になった値が保存されます。
        <br />
        メールアドレスからハッシュ値を計算することは簡単にできますが、逆にハッシュ値からメールアドレスを逆算するのは非常に困難です。
        <br />
        ですので、このサービスで IP
        アドレスやメールアドレスのハッシュ値（ID）が送り先に開示されたとしても、そこから
        IP アドレスやメールアドレスが知られることは無いのでご安心ください。
      </div>
      <h3>TODO</h3>
      <div>
        めんどくさいので実装していないけど本来必要な機能がたくさんあります。
        <br />
        ・パスワードを忘れたときのリセット機能（作者に連絡してください。手動でリセットします。）
      </div>
    </>
  );
};

export default Help;
